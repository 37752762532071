// index.js

import { Elm } from './Main.elm'
import {getConfig } from './config.js';




var serviceUrl =getConfig()["backend-url"];
console.log("Service url " + serviceUrl);
var app = Elm.Main.init({
    

    node: document.querySelector('main'),
    flags: {"serviceUrl": getConfig()["backend-url"]}
})

    

console.log("app " + app);
console.log(app);
console.log(app.ports);




document.onSignIn = function (googleUser) {
    var profile = googleUser.getBasicProfile();
    var token =  googleUser.getAuthResponse().id_token;



    // const invocation = new XMLHttpRequest();
    // const url = 'http://localhost:7000/signin/';

    // function handler () {
    //   if(invocation.readyState === 4 && invocation.status === 200) {
    //     console.log(invocation.responseText);

    //     const invocationTat = new XMLHttpRequest();
    //     const urlTat = 'http://localhost:7000/tat/nipt?startDate=2019-07-01&endDate=2019-09-01';
    
    // invocationTat.open("GET", urlTat);
    // invocationTat.withCredentials =true;
    // invocationTat.send();
    

    //   }}


    // function callOtherDomain() {
    //   if (invocation) {
    //     invocation.open('POST', url, true);
    //     invocation.withCredentials = true;
    //     invocation.setRequestHeader("Content-Type", "application/json");
    //     invocation.onreadystatechange = handler;
    //     invocation.send(token); 
    //   }
    // }
    // //callOtherDomain();


    app.ports.toElm.send(token);


  console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
  console.log('Name: ' + profile.getName());
  console.log('Image URL: ' + profile.getImageUrl());
  console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
};




app.ports.scrollToBottom.subscribe(function (elementId) {
  var textarea = document.getElementById(elementId);
  if (textarea) {
      textarea.scrollTop = textarea.scrollHeight;
  }
});

app.ports.scrollToTop.subscribe(function (elementId) {
  var textarea = document.getElementById(elementId);
  if (textarea) {
      textarea.scrollTop = textarea.scrollHeight = 0;
  }
});